import React, { useEffect, useState } from 'react'
import Router from 'next/router'
/* eslint-disable-next-line */
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
/* eslint-disable-next-line */
import { Swiper, SwiperSlide } from 'swiper/react'
/* eslint-disable-next-line */
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

import Creator1Icon from '@/assets/img/home/topics/creator_1_icon.webp'
import Creator1Img from '@/assets/img/home/topics/creator_1_img.webp'
import Creator2Icon from '@/assets/img/home/topics/creator_2_icon.webp'
import Creator2Img from '@/assets/img/home/topics/creator_2_img.webp'
import Creator3Icon from '@/assets/img/home/topics/creator_3_icon.webp'
import Creator3Img from '@/assets/img/home/topics/creator_3_img.webp'
import Creator4Icon from '@/assets/img/home/topics/creator_4_icon.webp'
import Creator4Img from '@/assets/img/home/topics/creator_4_img.webp'
import Creator5Icon from '@/assets/img/home/topics/creator_5_icon.webp'
import Creator5Img from '@/assets/img/home/topics/creator_5_img.webp'
import Creator6Icon from '@/assets/img/home/topics/creator_6_icon.webp'
import Creator6Img from '@/assets/img/home/topics/creator_6_img.webp'
import Creator7Icon from '@/assets/img/home/topics/creator_7_icon.webp'
import Creator7Img from '@/assets/img/home/topics/creator_7_img.webp'
import Creator8Icon from '@/assets/img/home/topics/creator_8_icon.webp'
import Creator8Img from '@/assets/img/home/topics/creator_8_img.webp'
import Creator9Icon from '@/assets/img/home/topics/creator_9_icon.webp'
import Creator9Img from '@/assets/img/home/topics/creator_9_img.webp'
import Creator10Icon from '@/assets/img/home/topics/creator_10_icon.webp'
import Creator10Img from '@/assets/img/home/topics/creator_10_img.webp'
import Creator11Icon from '@/assets/img/home/topics/creator_11_icon.webp'
import Creator11Img from '@/assets/img/home/topics/creator_11_img.webp'
import Creator12Icon from '@/assets/img/home/topics/creator_12_icon.webp'
import Creator12Img from '@/assets/img/home/topics/creator_12_img.webp'

const creators = [
  {
    name: 'イツク',
    icon: Creator1Icon.src,
    image: Creator1Img.src,
    accountIdentity: 'itsuc_24',
  },
  {
    name: 'kyokasan',
    icon: Creator2Icon.src,
    image: Creator2Img.src,
    accountIdentity: 'kyokasan0123',
  },
  {
    name: '菊池風風磨',
    icon: Creator3Icon.src,
    image: Creator3Img.src,
    accountIdentity: 'syota.bds',
  },
  {
    name: 'ペキチワのピノ🦁🍎',
    icon: Creator4Icon.src,
    image: Creator4Img.src,
    accountIdentity: 'piiinostagram',
  },
  {
    name: '駒沢アイソレーション',
    icon: Creator5Icon.src,
    image: Creator5Img.src,
    accountIdentity: 'kmzwisolation',
  },
  {
    name: 'みさた',
    icon: Creator6Icon.src,
    image: Creator6Img.src,
    accountIdentity: 'misa__528',
  },
  {
    name: 'シュガーソルト',
    icon: Creator7Icon.src,
    image: Creator7Img.src,
    accountIdentity: 'sugarsalt_official',
  },
  {
    name: 'なおみかcp🍊',
    icon: Creator8Icon.src,
    image: Creator8Img.src,
    accountIdentity: 'naomika0228a',
  },
  {
    name: '四郎',
    icon: Creator9Icon.src,
    image: Creator9Img.src,
    accountIdentity: 'shirou6464',
  },
  {
    name: 'ぴかりんず🐻',
    icon: Creator10Icon.src,
    image: Creator10Img.src,
    accountIdentity: '01pikarin18',
  },
  {
    name: 'ささみ',
    icon: Creator11Icon.src,
    image: Creator11Img.src,
    accountIdentity: 'sasami373',
  },
  {
    name: 'Dr.sebas',
    icon: Creator12Icon.src,
    image: Creator12Img.src,
    accountIdentity: 'sebas11111',
  },
]

type CreatorsData = {
  name: string
  icon: string
  image: string
  accountIdentity: string
}[]

const TopicsSwiper = () => {
  const [creatorsData, setCreatorsData] = useState<CreatorsData[]>([])

  useEffect(() => {
    const windowWidth = screen.width
    const slidesPerView = windowWidth < 768 ? 2 : 4
    const length = Math.ceil(creators.length / slidesPerView)
    const newArray = new Array(length)
      .fill(length)
      .map((_, i) => creators.slice(i * slidesPerView, (i + 1) * slidesPerView))
    setCreatorsData(newArray)
  }, [])

  const toCreatorPage = (accountIdentity: string) => {
    Router.push(`/@${accountIdentity}`)
  }

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination',
          enabled: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        className="home-swiper"
      >
        {creatorsData.map((data, index) => {
          return (
            <SwiperSlide className="home-swiper-slide" key={index}>
              {data.map((creator, creatorIndex) => {
                return (
                  <div
                    className="home-swiper-slide-content"
                    key={`creator-${creatorIndex}`}
                    onClick={() => toCreatorPage(creator.accountIdentity)}
                  >
                    <img
                      className="home-swiper-slide-image"
                      src={creator.image}
                      alt={creator.name}
                    />
                    <div className="home-swiper-slide-avatar">
                      <img src={creator.icon} alt={creator.name} />
                      <div className="home-swiper-slide-avatar-name">{creator.name}</div>
                    </div>
                  </div>
                )
              })}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="swiper-pagenation-area">
        <div className="swiper-pagination" />
        <div className="swiper-button-prev swiper-pagenation-button" />
        <div className="swiper-button-next swiper-pagenation-button" />
      </div>
    </>
  )
}

export default TopicsSwiper
