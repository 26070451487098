import React from 'react'
import Router from 'next/router'
import getConfig from 'next/config'
import { event as gevent } from '@/pages/api/gtag'
import Close from '@/assets/img/icons/Close_M.svg'

const { publicRuntimeConfig } = getConfig()

interface Props {
  visible: boolean
  setVisible: (val: boolean) => void
  loggedIn: boolean
}

const Menu = ({ visible, setVisible, loggedIn }: Props) => {
  const onClickSection = (sectionId: string) => {
    setVisible(false)
    Router.push(sectionId)
  }

  const toSignIn = () => {
    gevent({
      action: 'lp_menu_login',
      category: 'login',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}`)
      },
    })
  }

  const toSignUp = () => {
    gevent({
      action: 'lp_menu_newcreate',
      category: 'register',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
      },
    })
  }
  return (
    <>
      <div
        className={visible ? 'side-menu-bg visible' : 'side-menu-bg'}
        onClick={() => setVisible(false)}
      />
      <nav id="sideMenu" className={visible ? 'side-menu visible' : 'side-menu'}>
        <div className="side-menu-main">
          <div className="side-menu-close-button" onClick={() => setVisible(false)}>
            <Close />
          </div>
          <div className="side-menu-items">
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#about')}>
                ABOUT
              </div>
              <div className="description">FANMEについて</div>
            </div>
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#linkInBio')}>
                LINK IN BIO
              </div>
              <div className="description">リンクまとめ機能</div>
            </div>
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#apps')}>
                APPS
              </div>
              <div className="description">活動を支援するアプリ機能</div>
            </div>
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#mission')}>
                MISSION
              </div>
              <div className="description">フォロワーをファンにする</div>
            </div>
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#howTo')}>
                HOWTO
              </div>
              <div className="description">FANMEの始め方</div>
            </div>
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#topics')}>
                TOPICS
              </div>
              <div className="description">注目クリエイター</div>
            </div>
            <div className="side-menu-item">
              <div className="title" onClick={() => onClickSection('#contact')}>
                CONTACT
              </div>
              <div className="description">お問い合わせ</div>
            </div>
          </div>
        </div>
        <div id="sideMenuBottom" className="side-menu-bottom">
          <button className="signin-button" onClick={toSignIn}>
            ログイン
          </button>
          {loggedIn && (
            <button className="signup-button" onClick={toSignUp}>
              新規登録
            </button>
          )}
        </div>
      </nav>
    </>
  )
}

export default Menu
