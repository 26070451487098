import React, { createRef, useEffect, useState } from 'react'
import getConfig from 'next/config'
import Router from 'next/router'
import styled from '@emotion/styled'
import { event as gevent } from '@/pages/api/gtag'
import { useAuth, useCurrentUser } from '@/contexts/AuthContext'
import AppHead from '@/components/templates/AppHead'
import TopicsSwiper from '@/components/molecules/Home/TopicsSwiper'
import HomeMenu from '@/components/molecules/Home/Menu'
import FanmeMainWT from '@/assets/img/logo/LOGO_WT'
import FanmeMainBK from '@/assets/img/logo/LOGO_BK.svg'
import DividerLine from '@/assets/img/logo/DividerLine.webp'
import ArrowBack from '@/assets/img/icons/Arrow_Top.svg'
import User from '@/assets/img/icons/Login.svg'
import MenuButton from '@/assets/img/icons/ExpandMenuWhite.svg'
import ProfileMain from '@/assets/img/home/img_LP_main.webp'
import AboutImg1 from '@/assets/img/home/about/img_About_1.webp'
import AboutImg2 from '@/assets/img/home/about/img_About_2.webp'
import LinkInBioImg from '@/assets/img/home/linkInBio/img_LinkInBio.webp'
import AppLockAlbum from '@/assets/img/home/apps/app_lock_album.webp'
import AppVoiceCollection from '@/assets/img/home/apps/app_voice_collection.webp'
import AppLockMovie from '@/assets/img/home/apps/app_lock_movie.webp'
import AppQuestionCorner from '@/assets/img/home/apps/app_question_corner.webp'
import MissionImg from '@/assets/img/home/mission/mission_image.svg'
import MissionStep1 from '@/assets/img/home/mission/img_Step1.webp'
import MissionStep2 from '@/assets/img/home/mission/img_Step2.webp'
import FanActivity1 from '@/assets/img/home/mission/img_FanActivity_1.webp'
import FanActivity2 from '@/assets/img/home/mission/img_FanActivity_2.webp'
import FanActivity3 from '@/assets/img/home/mission/img_FanActivity_3.webp'
import FanActivity4 from '@/assets/img/home/mission/img_FanActivity_4.webp'
import HowTo1 from '@/assets/img/home/howTo/img_HowTo_1.webp'
import HowTo2 from '@/assets/img/home/howTo/img_HowTo_2.webp'
import HowTo3 from '@/assets/img/home/howTo/img_HowTo_3.webp'
import FiveMinutes from '@/assets/img/home/img_FiveMinutes.webp'
import YourName from '@/assets/img/home/img_YourName.webp'

import { CreatorData } from '@/types/Creator.type'
import { Colors, Styles } from '@/fanmeUi/constants/style'
import { APP_INSTALL_PERMISSION_REQUEST_URL } from '@/constants/apps'
import ButtonBase from '@/components/atoms/Button'
import type { NextPage } from 'next'

const { publicRuntimeConfig } = getConfig()

const FooterFloatPanel = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: ${() => `${Styles.space}px`};
  z-index: 2;
  background-color: ${() => Colors.WHITE};
  transform: translate3d(0, 0, 0);
  border-radius: ${() => `${Styles.radius}px ${Styles.radius}px 0 0`};
  > div {
    max-width: 480px;
    margin: 0 auto;
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const Home: NextPage = () => {
  const { token, fetched } = useAuth()
  const { userNotExists } = useCurrentUser()
  const [sideMenuVisible, setSideMenuVisible] = useState(false)
  const [signUpButtonVisible, setSignUpButtonVisible] = useState(true)
  const showFooterPanel = fetched && (!token || userNotExists)
  const footerPanelRef = createRef<HTMLDivElement>()
  const [footerPanelHeight, setFooterPanelHeight] = useState<number | undefined>()

  useEffect(() => {
    if (footerPanelRef.current && showFooterPanel) {
      setFooterPanelHeight(footerPanelRef.current.getBoundingClientRect().height)
    } else {
      setFooterPanelHeight(undefined)
    }
  }, [footerPanelRef, showFooterPanel])

  const openMenu = () => {
    setSideMenuVisible(true)
    gevent({
      action: 'lp_menu',
      category: 'other',
    })
  }

  const toSignIn = () => {
    Router.push(`${publicRuntimeConfig.AUTH_URL}`)
  }

  const toSignUp = () => {
    gevent({
      action: 'lp_newcreate',
      category: 'register',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
      },
    })
  }

  const toCreatorPage = () => {
    if (!loginData) return
    Router.push(`/@${loginData.account_identity}`)
  }

  const toInfoPage = () => {
    Router.push('https://fanme-support.zendesk.com/hc/ja/requests/new')
  }

  const toPageTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const watchScroll = () => {
    const basePosition = 200
    const scrollPosition = window.scrollY
    const pageTopBtn = document.querySelector('.page-top-button')
    if (pageTopBtn) {
      if (basePosition <= scrollPosition) {
        pageTopBtn.classList.add('active')
      } else {
        pageTopBtn.classList.remove('active')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', watchScroll)
    return () => {
      window.removeEventListener('scroll', watchScroll)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = sideMenuVisible ? 'hidden' : 'visible'

    // アドレスバーの上にボタンを設置するため
    const elemLoginMenu = document.getElementById('sideMenu')
    const elemLoginMenuBottom = document.getElementById('sideMenuBottom')
    if (elemLoginMenu && elemLoginMenuBottom)
      elemLoginMenuBottom.style.marginBottom =
        elemLoginMenu.clientHeight - screen.height + 24 + 'px'
  }, [sideMenuVisible])

  const [loginData, setLoginData] = useState<CreatorData>()
  const getLoginUserData = async () => {
    try {
      const res = await fetch(`${publicRuntimeConfig.API_BASE_URL}/creators/self`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(res => res.json())
      setLoginData(res)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (token) {
      setSignUpButtonVisible(false)
      getLoginUserData()
    }
  }, [token])

  useEffect(() => {
    // 本番のみFacebook Pixelを読み込む
    if (process.env.NODE_ENV === 'production') {
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          // Pixelタグ挿入
          ReactPixel.init(process.env.FB_PIXEL_ID || '')
          ReactPixel.pageView()
        })
    }
  }, [])

  return (
    <>
      <AppHead
        creatorName={null}
        title={'FANME（ファンミー） | ファンとつながるクリエイター活動まとめサービス'}
        description={`FANMEは、あなたの活動まとめる「リンクまとめサービス」です。\n複数のSNSや様々な記事を、無料で簡単にまとめることができます✨\nあなただけのページを今すぐ作ろう！`}
        headerImage={'https://asset-fanme-link.s3.ap-northeast-1.amazonaws.com/meta/OGP2023.png'}
        url={`${publicRuntimeConfig.FRONT_URL}`}
        isFBPixelMeta={true}
        creatorId={null}
        creatorIcon={null}
      />
      <div className="home">
        <div className="home-head">
          <div className="home-header">
            <div className="home-header-left">
              <FanmeMainWT />
            </div>
            <div className="home-header-right">
              <div className="header-menu-button signin" onClick={toSignIn}>
                ログイン
              </div>
              {signUpButtonVisible && (
                <div className="header-menu-button signup" onClick={toSignUp}>
                  新規登録
                </div>
              )}
              {loginData?.icon && (
                <div className="header-creator-button" onClick={toCreatorPage}>
                  <img src={loginData.icon} alt={loginData.name} />
                </div>
              )}
              <MenuButton className="side-menu-button" onClick={openMenu} />
            </div>
          </div>
          <div className="home-head-content">
            <div className="title">
              <div className="main-title">
                <div>
                  <span>あなたを</span>
                  <span>知りたい人が</span>
                </div>
                <div>
                  <span>もっと増える。</span>
                </div>
              </div>
              <div className="sub-title">
                <div>次世代クリエイターSNS</div>
              </div>
            </div>
            <div className="images">
              <img className="profile-image" src={ProfileMain.src} alt="FANME Image" />
            </div>
          </div>
        </div>

        <HomeMenu
          visible={sideMenuVisible}
          setVisible={setSideMenuVisible}
          loggedIn={signUpButtonVisible}
        />

        <div className="section about" id="about">
          <div className="section-title-en">
            <p className="section-title-en-text">ABOUT</p>
            <p className="section-title-en-text">ABOUT</p>
            <p className="section-title-en-text">ABOUT</p>
          </div>
          <div className="section-title">
            <p>FANMEについて</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="about-contents">
            <div className="about-contents-item">
              <div className="about-detail">
                <div className="about-detail-title">
                  スマホだけで簡単に誰でも利用でき、
                  <br />
                  あなたの活動を支援するサービスです。
                </div>
                <div className="about-detail-description">
                  FANMEのリンクまとめ機能で、
                  <br />
                  フォロワーにあなたを知ってもらうきっかけを作り、
                  <br />
                  FANME APPSで、フォロワーのファン化や
                  <br />
                  マネタイズが可能です。
                </div>
              </div>
            </div>
            <div className="about-contents-images">
              <img className="about-contents-images-item" src={AboutImg1.src} alt="FANME Image" />
              <img className="about-contents-images-item" src={AboutImg2.src} alt="FANME Image" />
            </div>
          </div>
        </div>

        <div className="section link-in-bio" id="linkInBio">
          <div className="section-title-en">
            <p className="section-title-en-text">LINK IN BIO</p>
            <p className="section-title-en-text">LINK IN BIO</p>
            <p className="section-title-en-text">LINK IN BIO</p>
          </div>
          <div className="section-title">
            <p>リンクまとめ機能</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="link-in-bio-contents">
            <div className="link-in-bio-contents-images">
              <img
                className="link-in-bio-contents-images-item"
                src={LinkInBioImg.src}
                alt="LINK Image"
              />
            </div>
            <div className="link-in-bio-contents-margin">
              <div className="link-in-bio-contents-item">
                <div className="link-in-bio-detail">
                  <div className="link-in-bio-detail-title">
                    あなたのSNSや⾒てほしい動画や
                    <br />
                    コンテンツだけでなく、通販サイトや
                    <br />
                    ⾳楽サイトにも⾃由に誘導できる！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section apps" id="apps">
          <div className="section-title-en">
            <p className="section-title-en-text">APPS</p>
            <p className="section-title-en-text">APPS</p>
            <p className="section-title-en-text">APPS</p>
          </div>
          <div className="section-title">
            <p>活動を支援するアプリ機能</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="apps-sub-title">
            <span>あなたの友達やファンに対して、</span>
            <span>交流を深める為のサービスを展開！</span>
            <br />
            <span>FANME APPSを使ってファン化や、</span>
            <span>マネタイズに活⽤！</span>
          </div>
          <div className="apps-example">
            <div className="apps-example-item">
              <img src={AppLockAlbum.src} alt="ロックアルバム" />
              <div className="apps-example-item-title">ロックアルバム</div>
              <div className="apps-example-item-description">
                ⾃分の写真を1枚から
                <br />
                好きな値段で販売できる
              </div>
            </div>
            <div className="apps-example-item">
              <img src={AppVoiceCollection.src} alt="ボイスコレクション" />
              <div className="apps-example-item-title">ボイスコレクション</div>
              <div className="apps-example-item-description">
                ⾃分の⾳声を販売して
                <br />
                アラームや通知⾳に
              </div>
            </div>
            <div className="apps-example-item">
              <img src={AppLockMovie.src} alt="ロックムービー" />
              <div className="apps-example-item-title">ロックムービー</div>
              <div className="apps-example-item-description">
                ⾃分の動画を1本から、
                <br />
                好きな値段で販売できる
              </div>
            </div>
            <div className="apps-example-item">
              <img src={AppQuestionCorner.src} alt="質問コーナー" />
              <div className="apps-example-item-title">質問コーナー</div>
              <div className="apps-example-item-description">
                友達やファンから
                <br />
                質問をもらって⾃由に回答
              </div>
            </div>
          </div>
        </div>

        <div className="section mission" id="mission">
          <div className="section-title-en">
            <p className="section-title-en-text">MISSION</p>
            <p className="section-title-en-text">MISSION</p>
            <p className="section-title-en-text">MISSION</p>
          </div>
          <div className="section-title">
            <p>フォロワーをファンにする</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="first-flow">
            <div className="first-flow-title">
              <span>現状の広告マネタイズは</span>
              <span className="accent">‘チャンネル数’に依存</span>
            </div>
            <MissionImg />
          </div>
          <div className="second-flow">
            <div className="black-triangle" />
            <div className="second-flow-logo">
              <FanmeMainBK />
            </div>
            <div className="mission-catch-copy">
              <span>ファン化によるあなたの</span>
              <span>クリエイター活動のサポートを!</span>
            </div>
          </div>
          <div className="third-flow">
            <div className="white-triangle" />
            <div className="steps">
              <div className="step-item step1">
                <div className="step-item-detail">
                  <div className="step">
                    <p className="step-text">Step.1</p>
                    <p className="step-text">Step.1</p>
                    <p className="step-text">Step.1</p>
                  </div>
                  <div className="step-item-detail-description">
                    <div className="step-item-detail-description-item">
                      SNSから
                      <div className="under-line-text">
                        FANMEへ
                        <div className="under-line" />
                      </div>
                    </div>
                    <div className="step-item-detail-description-item">
                      FANMEから
                      <div className="under-line-text">
                        他のリンクへ
                        <div className="under-line" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step-item-image">
                  <img src={MissionStep1.src} alt="Link image" />
                </div>
              </div>
              <div className="step-item step2">
                <div className="step-item-detail">
                  <div className="step">
                    <p className="step-text">Step.2</p>
                    <p className="step-text">Step.2</p>
                    <p className="step-text">Step.2</p>
                  </div>
                  <div className="step-item-detail-description">
                    <div className="step-item-detail-description-item">
                      FANMEで
                      <div className="under-line-text">
                        あなたのファンへ
                        <div className="under-line" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step-item-image">
                  <img src={MissionStep2.src} alt="Apps Icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="fourth-flow">
            <div className="beige-triangle" />
            <div className="mission-catch-copy">
              <span>ファンがいるから</span>
              <span>新たな活動の後押しに!</span>
            </div>
            <div className="fourth-flow-images">
              <img src={FanActivity1.src} alt="ブランド立ち上げ" />
              <img src={FanActivity2.src} alt="ショップオープン" />
              <img src={FanActivity3.src} alt="グッズ販売" />
              <img src={FanActivity4.src} alt="音楽活動" />
            </div>
          </div>
        </div>

        <div className="section how-to" id="howTo">
          <div className="section-title-en">
            <p className="section-title-en-text">HOW TO</p>
            <p className="section-title-en-text">HOW TO</p>
            <p className="section-title-en-text">HOW TO</p>
          </div>
          <div className="section-title">
            <p>FANMEの始め方</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="steps">
            <div className="step-item step1">
              <div className="step-item-detail">
                <div className="step">
                  <p className="step-text">Step.1</p>
                  <p className="step-text">Step.1</p>
                  <p className="step-text">Step.1</p>
                </div>
                <div className="step-item-detail-title">
                  まずはカバー写真を
                  <br />
                  設定しよう
                </div>
                <div className="step-item-detail-description">
                  ⾃分を象徴する画像を設定して
                  <br />
                  ⾒に来てくれた⽅に印象を残せます。
                </div>
              </div>
              <div className="step-item-image">
                <img src={HowTo1.src} alt="カバー写真" />
              </div>
            </div>
            <div className="step-item step2">
              <div className="step-item-image">
                <img src={HowTo2.src} alt="リンクボタン" />
              </div>
              <div className="step-item-detail">
                <div className="step">
                  <p className="step-text">Step.2</p>
                  <p className="step-text">Step.2</p>
                  <p className="step-text">Step.2</p>
                </div>
                <div className="step-item-detail-title">
                  好きなレイアウトで
                  <br />
                  アイコンを設置しよう
                </div>
                <div className="step-item-detail-description">
                  好きなレイアウトでアイコンを設置し
                  <br />
                  ⾃由にリンク先を設定しよう
                </div>
              </div>
            </div>
            <div className="step-item step3">
              <div className="step-item-detail">
                <div className="step">
                  <p className="step-text">Step.3</p>
                  <p className="step-text">Step.3</p>
                  <p className="step-text">Step.3</p>
                </div>
                <div className="step-item-detail-title">
                  作ったFANMEページを
                  <br />
                  他のSNSに設置しよう
                </div>
              </div>
              <div className="step-item-image">
                <img src={HowTo3.src} alt="SNS設置" />
              </div>
            </div>
          </div>
        </div>

        <div className="section topics" id="topics">
          <div className="section-title-en">
            <p className="section-title-en-text">TOPICS</p>
            <p className="section-title-en-text">TOPICS</p>
            <p className="section-title-en-text">TOPICS</p>
          </div>
          <div className="section-title">
            <p>注目クリエイター</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="topics-sub-title">
            <span>YouTuber、TikToker、インスタグラマーを</span>
            <span>はじめアーティストやタレント、ミュージシャン、</span>
            <br />
            <span>ブランドやショップなど、さまざまな⽅がFANME</span>
            <span>を利⽤していきます！</span>
          </div>
          <TopicsSwiper />
          <div className="ready-sector">
            <div className="ready-sector-detail">
              <div className="left-wrapper">
                <div className="title">
                  <div className="main-title">
                    <div>
                      <span>あなたを</span>
                      <span>知りたい人が</span>
                    </div>
                    <div>
                      <span>もっと増える。</span>
                    </div>
                  </div>
                  <div className="sub-title">
                    <div>次世代クリエイターSNS</div>
                  </div>
                </div>
              </div>
              <div className="right-wrapper">
                <img className="message-image" src={FiveMinutes.src} alt="アカウント作成" />
                <img
                  className="search-image"
                  src={YourName.src}
                  onClick={toSignUp}
                  alt="FANME LINK"
                />
              </div>
            </div>
            <div className="ready-sector-button" onClick={toSignUp}>
              無料で新規アカウント作成
            </div>
          </div>
        </div>

        <div className="section contact" id="contact">
          <div className="section-title-en">
            <p className="section-title-en-text">CONTACT</p>
            <p className="section-title-en-text">CONTACT</p>
            <p className="section-title-en-text">CONTACT</p>
          </div>
          <div className="section-title">
            <p>お問い合わせ</p>
            <img src={DividerLine.src} alt="カラー線" />
          </div>
          <div className="contact-sub-title">
            <span className="next-line-point">
              <span className="bold-accent">サービスに関するご質問</span>はもちろん、
            </span>
            <span className="next-line-point">
              <span className="bold-accent">取材やメディア掲載</span>に関わる
            </span>
            <br />
            <span className="next-line-point">
              お問い合わせも
              <StyledLink
                className="contact-link"
                href="https://fanme-support.zendesk.com/hc/ja/requests/new"
              >
                こちら
              </StyledLink>
              から承ります。
            </span>
          </div>
          <div className="contact-button" onClick={toInfoPage}>
            お問い合わせ
          </div>
        </div>

        <div
          className="page-top-button"
          onClick={toPageTop}
          style={{
            marginBottom: footerPanelHeight,
          }}
        >
          <ArrowBack />
          <div className="page-top-button-text">PAGE TOP</div>
        </div>

        {signUpButtonVisible && (
          <div
            className="mobile-signup-button"
            onClick={toSignUp}
            style={{
              marginBottom: footerPanelHeight,
            }}
          >
            <User />
            <div className="mobile-signup-button-text">新規登録</div>
          </div>
        )}

        <div className="footer">
          <div className="footer-section">
            <a href="https://www.torihada.co.jp/" target="_blank" rel="noreferrer">
              運営会社
            </a>
            <a href={`${publicRuntimeConfig.FRONT_URL}/terms`} target="_blank" rel="noreferrer">
              利用規約
            </a>
            <a href="https://www.torihada.co.jp/privacy-policy/" target="_blank" rel="noreferrer">
              プライバシーポリシー
            </a>
          </div>
          <div className="copy-right">COPYRIGHT 2020 TORIHADA INC. ALL RIGHTS RESERVED.</div>
        </div>
      </div>

      {showFooterPanel && (
        <FooterFloatPanel ref={footerPanelRef}>
          <div>
            <ButtonBase
              text="クリエイター無料サポート申請はこちらから！"
              width={'100%'}
              height="32px"
              textColor={Colors.WHITE}
              fontFamily="Noto Sans JP"
              fontWeight="700"
              bgColor={Colors.PRIMARY_GRAY}
              borderRadius="16px"
              onClick={async () => {
                window.open(APP_INSTALL_PERMISSION_REQUEST_URL)
              }}
            ></ButtonBase>
          </div>
        </FooterFloatPanel>
      )}
    </>
  )
}

export default Home
